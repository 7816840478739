<template>
    <div class="order-content">
        <el-table :data="orderList" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px" height="100%">
            <el-table-column label="产品名称" align="left" width="380px">
                <template slot-scope="scope">
                    <router-link class="answer" :to="{path: '/goods/detail', query: {g_id: scope.row.id }}" target="_blank">
                        <img :src="scope.row.goods_img" alt="">
                        <span class="text">{{scope.row.goods_title}}</span>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column prop="order_no" label="订单编号" align="center"></el-table-column>
            <el-table-column label="单价/数量" align="center">
                <template slot-scope="scope">
                    <p>￥{{scope.row.unit_price}}</p>
                    <p>   x {{scope.row.number}}</p>
                </template>
            </el-table-column>
            <el-table-column  label="物流信息" align="center">
                <template slot-scope="scope">
                    <p>{{scope.row.logistics>0?scope.row.logistics:'暂无快递信息'}}</p>
                    <p>{{scope.row.odd_numbers>0?scope.row.odd_numbers:'暂无物流单号'}}</p>
                </template>
            </el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center"></el-table-column>
            <el-table-column label="订单状态" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.order_status}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="scoring_operation" label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="primary" v-if="scope.row.order_status==='（锁定）待发货'||scope.row.order_status==='通过（（删除）待发货）'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>
                    <el-link type="primary" v-if="scope.row.order_status==='（锁定）已发货'||scope.row.order_status==='通过（（删除）已发货）'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>
                    <el-link type="primary" v-if="scope.row.order_status==='（锁定）交易完成'||scope.row.order_status==='通过（（删除）交易完成）'||scope.row.order_status==='通过（（换货）待发货）'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="modifyOrderTitle" @close="resetForm()"
                   custom-class="green-dialog02" :visible.sync="modifyOrderDialog" width="500px">
            <el-form :model="modifyOrderForm" ref="modifyOrderForm" label-position="right" label-width="100px">
                <el-form-item label="订单号:"  prop="order_no">
                    <div class="order-no-title">{{modifyOrderForm.order_no}}</div>
                </el-form-item>
                <el-form-item prop="delivery_status" label="出库情况" v-if="this.orderStatus === '（锁定）待发货'||this.orderStatus === '通过（（删除）待发货）'
                    ||this.orderStatus === '（锁定）已发货'||this.orderStatus === '通过（（删除）已发货）'">
                    <el-select v-model="modifyOrderForm.delivery_status"  clearable placeholder="请选择出库情况" style="width:360px"
                               @change="deliveryStatus">
                        <el-option
                                v-for="item in deliveryStatusList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="audit_reasons" label="审核原因" v-else>
                    <el-select v-model="modifyOrderForm.audit_reasons" clearable placeholder="请选择审核原因" style="width:360px"
                               @change="auditReasons">
                        <el-option
                                v-for="item in auditReasonsList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="reasons_rejection" label="驳回原因">
                    <el-select v-model="modifyOrderForm.reasons_rejection" clearable placeholder="请选择驳回原因" style="width:360px"
                               @change="reasonsRejection">
                        <el-option
                                v-for="item in reasonsRejectionList"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <p class="notes-title">注：出库情况：审核通过需填写实际出库情况； 驳回原因：驳回时需选择驳回原因</p>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="modifyOrderDialog = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addOrderBtn(1)" :disabled="!hasOutBound&&!hasAuditReasons">通过</el-button>
                <el-button style="margin-left: 30px" type="primary" plain @click="addOrderBtn(2)" :disabled="!hasRejection&&!hasOutBound&&!hasAuditReasons">驳回</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {stuOpOrderList,stuOpOrderOperation,stuOpModifyOrder} from '@/utils/apis.js'
    export default {
        name: "SalesOrder",
        data(){
            return{
                orderList:[],
                modifyOrderForm:{
                    logistics:'',
                    order_no:'',
                    odd_numbers:void 0,
                    delivery_status:'',
                    reasons_rejection:''
                },
                modifyOrderTitle:'',
                modifyOrderDialog:false,
                deliveryStatusList:[],
                reasonsRejectionList:[],
                auditReasonsList:[],
                orderId:'',
                orderStatus:'',
                scoringOperation:'',
                hasOutBound:false,//是否有选择出库情况
                hasRejection:false,//是否有选择驳回原因
                hasAuditReasons:false,//是否有选择审核原因
            }
        },
        mounted() {
            this.getStuOpOrderList()
        },
        methods:{
            // 获取订单列表
            getStuOpOrderList(){
                let param = {
                    type:3
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                stuOpOrderList(param).then((res) => {
                    this.orderList = res.data.list
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 出库
            deliveryStatus(e){
                if(e){
                    this.hasOutBound = true;
                }else{
                    this.hasOutBound = false;
                }
            },
            // 驳回
            reasonsRejection(e){
                if (e){
                    this.hasRejection = true;
                }else {
                    this.hasRejection = false;
                }
            },
            // 审核
            auditReasons(e){
                if (e){
                    this.hasAuditReasons = true;
                }else {
                    this.hasAuditReasons = false;
                }
            },
            // 获取订单操作
            getStuOpOrderOperation() {
                let param = {
                    id: this.orderId
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                stuOpOrderOperation(param).then((res) => {
                    this.deliveryStatusList = res.data.delivery_status
                    this.reasonsRejectionList = res.data.reasons_rejection
                    this.auditReasonsList = res.data.audit_reasons
                }).catch((err) => {
                    console.log(err);
                })
            },
            orderBtn(row) {
                console.log('row', row);
                this.modifyOrderDialog = true;
                this.orderId = row.id
                this.orderStatus = row.order_status
                this.modifyOrderForm.order_no = row.order_no
                this.getStuOpOrderOperation()
                if (this.orderStatus === '（锁定）待发货'||this.orderStatus === '通过（（删除）待发货）'
                    ||this.orderStatus === '（锁定）已发货'||this.orderStatus === '通过（（删除）已发货）') {
                    this.modifyOrderTitle = '退款单审核'
                }
                if (this.orderStatus === '（锁定）交易完成'||this.orderStatus === '通过（（删除）交易完成）') {
                    this.modifyOrderTitle = '退货单审核'
                }
                if (this.orderStatus === '（锁定）交易完成'||this.orderStatus === '通过（（换货）待发货）') {
                    this.modifyOrderTitle = '换货单审核'
                    this.modifyOrderForm.audit_reasons = row.audit_reasons;
                    this.modifyOrderForm.reasons_rejection = row.reasons_rejection;
                }
            },
            // 订单确定事件
            addOrderBtn(operation) {
                let param = {
                    id:this.orderId
                }
                if (this.$route.query.id) {
                    param.op_id = this.$route.query.id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                if (operation===1){
                    param.operation=1
                }else if (operation===2) {
                    param.operation=2
                }
                if (this.orderStatus === '（锁定）待发货'||this.orderStatus === '通过（（删除）待发货））'
                    ||this.orderStatus === '（锁定）已发货'||this.orderStatus === '通过（（删除）已发货））') {
                    param.delivery_status = this.modifyOrderForm.delivery_status
                    param.reasons_rejection = this.modifyOrderForm.reasons_rejection
                }
                if (this.orderStatus === '（锁定）交易完成'||this.orderStatus === '通过（（删除）交易完成）'
                    ||this.orderStatus === '（锁定）交易完成'||this.orderStatus === '通过（（换货）待发货）') {
                    param.audit_reasons = this.modifyOrderForm.audit_reasons
                    param.reasons_rejection = this.modifyOrderForm.reasons_rejection
                }
                stuOpModifyOrder(param).then((res) => {
                    this.$message.success(res.msg);
                    this.getStuOpOrderList()
                    this.modifyOrderDialog = false;
                    this.resetForm()
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 重置表单
            resetForm(){
                this.$refs.modifyOrderForm.resetFields();
                this.modifyOrderForm = this.$options.data().modifyOrderForm;
                this.hasOutBound = false;
                this.hasRejection=false;
                this.hasAuditReasons=false;
            },
        }
    }
</script>

<style scoped lang="scss">
    .order-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .answer{
            display: flex;
            align-items: center;
            img{
                width: 80px;
                height: 80px;
            }
            .text{
                padding-left: 12px;
            }
        }
        .notes-title{
            color: #999;
            padding: 0 100px;
        }
        .dialog-footer{
            margin-top: 20px;
            text-align: center;
        }
    }

</style>